import 'react-toastify/dist/ReactToastify.css';
import Lottie from 'lottie-react';
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

import block from './images/edited-block.svg'
import anim_one from './images/anim-1.json'
import anim_two from './images/anim-2.json'
import anim_three from './images/anim-3.json'
import crosspointImage from "./images/xp-mockup.jpg"
import kittImage from "./images/kitt-mockup.png"
import jomoImage from "./images/jomo-mockup.jpg"
import brush_anim from './images/ai-anim.json'
import ai_anim from './images/brush-anim.json'
import code_anim from './images/code-anim.json'

function App() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [about, setAbout] = useState("")

  const submit = () => toast.success("Thanks! We'll get back to you shortly.");

  return (
    <div>
    <ToastContainer />
      <section className="h-screen bg-[#E1EBFA]">

      <img className='z-10 absolute right-0 w-0 lg:w-3/4' alt="Blue Background" src={block}/>

        <header className="body-font z-20 relative pt-4">
          
          <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            
            <a className="flex title-font font-medium items-center mb-4 md:mb-0">
            <div className="bg-primary p-1 rounded-full">
              <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9523 6.2635L10.4523 18.2635L9.04784 17.7368L13.5478 5.73682L14.9523 6.2635ZM19.1894 12.0001L15.9698 8.78042L17.0304 7.71976L21.3108 12.0001L17.0304 16.2804L15.9698 15.2198L19.1894 12.0001ZM8.03032 15.2198L4.81065 12.0002L8.03032 8.78049L6.96966 7.71983L2.68933 12.0002L6.96966 16.2805L8.03032 15.2198Z" fill="#ffffff"/>
              </svg>
            </div>
              <span className="ml-3 text-xl">WT Software</span>
            </a>

            <nav className="md:ml-auto md:mr-auto flex flex-wrap gap-12 items-center text-base justify-center">
              <a className="hover:text-gray-900 hover:cursor-pointer" href='#services'>Services</a>
              <a className="hover:text-gray-900 hover:cursor-pointer" href='#about'>About</a>
              <a className="hover:text-gray-900 hover:cursor-pointer" href='#projects'>Projects</a>
            </nav>
            <a href='#contact'>
            <button className="text-white inline-flex items-center bg-primary border-0 py-1 px-3 focus:outline-none hover:bg-primaryHover rounded text-base mt-4 md:mt-0">Contact Us
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
            </a>
          </div>
        </header>

        <div className="container mx-auto flex px-16 py-12 md:py-32 md:flex-row flex-col items-center z-20 relative">
          
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center lg:pr-48">
            <h1 className="title-font md:text-6xl text-3xl mb-4 font-semibold">WT Software

            </h1>
            <p className="mb-8 font-light leading-relaxed md:text-xl">Full-service software development studio for all your business needs.</p>
            <div className="flex justify-center font-light">
              <a href='#services'><button className="inline-flex bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primaryHover rounded md:text-lg text-white">Services</button></a>
              <a href='#projects'><button className="ml-4 inline-flex bg-secondary border-0 py-2 px-10 focus:outline-none hover:bg-secondaryHover rounded md:text-lg">Our Projects</button></a>
            </div>
          </div>

          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <Lottie animationData={anim_two}/>
          </div>
        </div>

      </section>


      <section id="services" className="h-screen flex lg:items-center z-20 bg-white relative">

        <div className="container px-5 md:pb-24 md:pt-12 mx-auto">
          <div className="text-center md:mb-32">
            <h1 className="sm:text-4xl text-2xl font-semibold my-8 md:mb-16">Our Services</h1>
          </div>

          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6 font-light justify-center lg:gap-24">

            <div className="md:p-4 md:w-3/12 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 mb-5 flex-shrink-0">
              <Lottie animationData={brush_anim}/>
              </div>
              <div className="flex-grow">
                <h2 className="text-lg md:text-xl title-font font-medium mb-3">Web Design</h2>
                <p className="leading-relaxed text-sm lg:text-base">Providing clients with engaging websites and beautifully crafted software experiences.</p>
              </div>
            </div>

            <div className="md:p-4 md:w-3/12 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 mb-5 flex-shrink-0">
                <Lottie animationData={code_anim}/>
              </div>
              
              <div className="flex-grow">
                <h2 className="text-lg md:text-xl  title-font font-medium mb-3">Bespoke Software</h2>
                <p className="leading-relaxed text-sm lg:text-base">Building custom software to suit your business needs. Integration with and extension of your current systems.</p>
              </div>
            </div>

            <div className="md:p-4 md:w-3/12 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 mb-5 flex-shrink-0 p-2">
              <Lottie animationData={ai_anim}/>
              </div>
              <div className="flex-grow">
                <h2 className="text-lg md:text-xl  title-font font-medium mb-3">AI Solutions</h2>
                <p className="leading-relaxed text-sm lg:text-base">Integration of cutting edge AI systems into your business to unlock innovative solutions to your problems.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="h-screen bg-[#E1EBFA]">

      <img className='z-10 absolute h-100vh left-0 w-7/12 rotate-180' alt="Blue Background" src={block}/>

        <div className='relative z-20'>
            <div className="container mx-auto flex px-5 py-8 md:py-24 md:flex-row flex-col items-center ">
              <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                <Lottie animationData={anim_one}/>
              </div>
              
              <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                <h1 className="sm:text-4xl text-2xl mb-6 font-semibold">About Us

                </h1>
                <div className='font-light text-sm md:text-base'>
                  <p className="mb-6 leading-relaxed">At WT Software we leverage our years of experience in delivering high quality products to build software that you will love!</p>

                  <p className="mb-6 leading-relaxed">We work closely with our clients from the start to understand their requirements and identify intelligent solutions. Once you are happy with a solution we will iteratively build your software, with regular sessions to update you on progress and take on board your requirements.</p>

                  <p className="mb-6 leading-relaxed">We will support you up to and after final deployment, giving you options for different deployment services or integrating with your existing infrastructure.</p>

                  <p className="mb-8 leading-relaxed">We've worked with clients in Healthcare, Finance and IT. <a className='text-blue-500 hover:cursor-pointer' href="#contact">Contact us</a> to find out more about how we can help you!</p>
                </div>

              </div>
            </div>


        </div>

      </section>

      <section id="projects" className='h-screen z-20 bg-white relative'>
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font lg:text-4xl text-3xl mb-4 font-semibold">Avamore Capital

            </h1>
            <div className='font-light text-sm lg:text-base'>
              <p className="mb-8 leading-relaxed">
              Avamore Capital are a real-estate debt finance company, dealing with over £600m a year in assets. They were looking to speed up their daily workflows by automating labour-intensive document parsing tasks.
              </p>
              <p className="mb-8 leading-relaxed">
              We identified this as a challenge which could be addressed by leveraging recent developments in AI language models. 
              Hence we worked with Avamore to build a full stack platform for their credit analysts, which would automatically parse, extract and process relevant data.
              This solution integrates with their existing processes, and reduces new deal processing time for credit analysts.
              </p>
            </div>

            <div className='pl-1 w-3/12 border border-x-0 border-t-0 mb-3'>Key Features:</div>
            <div className="flex gap-4 font-light">
              <div className="bg-gray-100 rounded-xl px-2 py-1">AI Language Models</div>
              <div className="bg-gray-100 rounded-xl px-2 py-1">Process Automation</div>
              <div className="bg-gray-100 rounded-xl px-2 py-1">Document Analysis</div>
            </div>

          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 hidden md:block">
            <img className="object-cover object-center rounded-lg" alt="hero" src={crosspointImage}/>
          </div>
        </div>
      </section>


      <section className='h-screen bg-[#E1EBFA] relative'>
          <img className='z-10 absolute h-100vh right-0 bottom-0 w-11/12' alt="Blue Background" src={block}/>
        
        <div className="z-20 relative container mx-auto flex px-5 py-24 md:flex-row flex-col items-center md:text-gray-100">
         
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 hidden md:block">
            <img className="object-cover object-center rounded-lg" alt="hero" src={kittImage}/>
          </div>  

          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font lg:text-4xl text-3xl mb-4 font-semibold">Kitt Medical
            </h1>
            <div className='font-light text-sm lg:text-base'>
              <p className="mb-8 leading-relaxed">
                Kitt Medcial are a healthcare startup looking to revolutionise allergy care. Their product provides accessible, easy to use, anaphylaxis kits in public places, with an initial focus on UK schools.
              </p>
              <p className="mb-8 leading-relaxed">
                We worked closely with the technical and design staff at Kitt medical to build a platform for them to manage anaphylaxis kits and provide training for users. 
                The platform is already used by over 200 UK schools and supports over 1000 school medical staff. Kitt Medical valued not only a technically capable solution but 
                also an intuitive design to cater to a range of technical abilities.
              </p>
            </div>

            <div className='pl-1 w-3/12 border border-x-0 border-t-0 mb-3'>Key Features:</div>
            <div className="flex gap-4 font-light">
              <div className="bg-[#75b9c4] rounded-xl px-2 py-1">Web Platform</div>
              <div className="bg-[#75b9c4] rounded-xl px-2 py-1">Stock Management</div>
            </div>
          </div>

        </div>
      </section>

      <section className='h-screen bg-white z-20 relative'>
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font lg:text-4xl text-3xl mb-4 font-semibold">JoMo Mail

            </h1>
            <div className='font-light text-sm lg:text-base' >
              <p className="mb-8 leading-relaxed">
                JoMo Mail (a subsidiary of I.T. Just Done) is a challenger webmail service, looking to build a more privacy-focussed, customizable, email subscription service with
                no ads and no inbox trawling. The service was designed as a more consumer facing side of ITJD's current internal enterprise webmail solutions. 
              </p>
              <p className="mb-8 leading-relaxed">
                We worked with ITJD from the inception of JoMo configuring their webmail server. But the main focus was on building a platform for users to view and manage their mail. The service 
                allows users to purchase optional expansions to the base service such as regular back ups and a privacy-focussed secure-sending feature.
              </p>
            </div>

            <div className='pl-1 w-3/12 border border-x-0 border-t-0 mb-3'>Key Features:</div>
            <div className="flex gap-4 font-light">
              <div className="bg-gray-100 rounded-xl px-2 py-1">Web Platform</div>
              <div className="bg-gray-100 rounded-xl px-2 py-1">Email Server Configuration</div>
              <div className="bg-gray-100 rounded-xl px-2 py-1">Payment Integration</div>
            </div>


          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 hidden md:block">
            <img className="object-cover object-center rounded-lg" alt="hero" src={jomoImage}/>
          </div>
        </div>
      </section>


      <section id="contact" className='h-80vh pb-20 bg-[#E1EBFA]'>

    <div className='items-center flex flex-col md:flex-row gap-10'>
      <div className="container px-5 pt-24 md:ml-20 md:w-1/2">

          <div className="flex flex-col mb-12">
            <h1 className="sm:text-3xl text-2xl font-semibold title-font mb-4 ">Contact Us</h1>
            <div className='text-light'>
              <p className="leading-relaxed text-base font-light">Register with us below for a consultation. All our initial consultations
               are free, where we can have a discussion about your business needs and how we can help.</p>
            </div>
          </div>
          <div className="font-light">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
                  <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
                  <textarea onChange={(e) => setAbout(e.target.value)} value={about} id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out">

                  </textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <button onClick={() => {
                   setAbout("") 
                   setName("") 
                   setEmail("") 
                   submit()
                  }
                } className="flex mx-auto text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-primaryHover rounded text-lg">Register</button>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <Lottie animationData={anim_three}/>
        </div>

    </div>


      </section>

    </div>
  );
}

export default App;
